import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import HeroSmall from "../components/HeroSmall";
import TeamMember from "../components/TeamMember";
import hedwig from "../../static/hedwig.jpg";
import judith from "../../static/judith.jpg";
import warre from "../../static/warre.jpg";
import ella from "../../static/ella.jpg";
import hero from "../../static/hero-team.jpg";

const TeamPage = ({ location, data }) => {
  const url = location.pathname ? location.pathname : "";

  return (
    <div className="main">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Onze therapeuten – Kine-Osteo Hagers</title>
        <meta name="description" content="Maak kennis met onze therapeuten." />
      </Helmet>
      <Layout routeUrl={url}>
        <section className="section section--no-mt">
          <div className="container">
            <h1 className="page-title">Therapeuten</h1>
          </div>
        </section>
        <TeamMember
          image={hedwig}
          info="Gedeconventioneerd KINESITHERAPEUT"
          title="Hedwig Hagers"
          desc="Hedwig leidt het team van Kine-Osteo-Hagers. Hij behandelt in de praktijk een breed spectrum aan klachten met dank aan zijn jarenlange ervaring als kinesitherapeut en professioneel sporter. Hij neemt ook het merendeel van het administratieve werk voor zijn rekening."
        />
        <TeamMember
          altClass="section--light"
          image={judith}
          info="GEDECONVENTIONEERD KINESITHERAPEUT"
          title="Judith Van de Gracht"
          desc={[
            <p>
              Judith studeerde in 2013 af als ‘master kinesitherapie bij
              inwendige aandoeningen’ na een interuniversitaire opleiding. In
              2014 werkte ze een tweede master af in de ‘kinesitherapie bij
              musculoskeletale aandoeningen’ aan de universiteit van Gent.
              Tenslotte behaalde ze in 2019 haar postgraduaat in de ‘pelvische
              reëducatie en perinatale kinesitherapie’.
            </p>,
            <p>
              Ze werkt sinds 2016 bij Kine-Osteo-Hagers. Hiervoor was ze al
              werkzaam in het Universitair ziekenhuis van Gent, op de diensten
              urologie en materniteit. Binnen onze praktijk behandelt zij
              voornamelijk mama’s tijdens en na hun zwangerschap, patiënten met
              bekkenbodemproblemen en ademhalingsproblematieken.
            </p>,
          ]}
          gridClass="grid--rev"
        />
        <TeamMember
          image={warre}
          info="GEDECONVENTIONEERD KINESITHERAPEUT"
          title="Warre Demeurisse"
          desc={[
            <p>
             Warre studeerde af in 2023 als master in de revalidatiewetenschappen en de kinesitherapie met als specialisatie musculoskeletale aandoeningen en sport aan de universiteit van Gent.
            </p>,
             <p>
              Sinds september 2023 is hij begonnen aan de opleiding osteopathie aan de International Academy of Osteopathy waar hij de vier volgende jaren gevormd zal worden tot osteopaat.
            </p>,
            <p>
              Hij werkt sinds juli 2023 bij Kine-Osteo Hagers.
            </p>
          ]}
        />
        <TeamMember
          altClass="section--light"
          image={ella}
          info="GEDECONVENTIONEERD KINESITHERAPEUT"
          title="Ella Braems"
          desc={[
            <p>
               Ella studeerde in 2020 af als master in de revalidatiewetenschappen en kinesitherapie met als specialisatie musculoskeletale aandoeningen en sport aan de universiteit van Gent.
            </p>,
             <p>
              Na haar master besloot Ella om de opleiding myofasciale therapie te volgen waarvan ze nu ook alle modules afgerond heeft. Deze therapie zet in op de behandeling van triggerpoints 
         door middel van dry needling.
            </p>,
            <p>
               In 2022 heeft Ella succesvol het postgraduaat manuele therapie afgerond waardoor ze nu ook volwaardig manueel therapeut is.
            </p>,
            <p>Ze werkt sinds september 2023 bij Kine-Osteo Hagers.</p>
          ]}
          gridClass="grid--rev"
        />
      </Layout>
    </div>
  );
};

export default TeamPage;
