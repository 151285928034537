import React from "react";

const Hero = (props) => {
  return (
    <div className="hero">
      <div className="hero__image">
        <div className="visual visual--page-hero">
          <img src={props.hero} alt="" />
        </div>
      </div>
      <div className="hero__body">
        <h1 className="hero__title">{props.title}</h1>
        {props.heroDetails ? props.heroDetails : ""}
      </div>
    </div>
  );
};

export default Hero;
