import React from "react";

const TeamMember = (props) => {
  return (
    <section className={`section${props.altClass ? ` ` + props.altClass : ""}`}>
      <div className="team-member">
        <div className="container">
          <div
            className={`grid${
              props.gridClass ? ` ` + props.gridClass : ""
            } grid--align-center`}
          >
            <div className="grid__item medium--one-half">
              <div className="visual image-round">
                <img src={props.image} alt="" />
              </div>
            </div>
            <div className="grid__item medium--one-half">
              <h2 className="team-member__title">{props.title}</h2>
              <p className="team-member__info">
                <strong>STATUUT: </strong>
                {props.info}
              </p>
              <p className="team-member__desc">{props.desc}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamMember;
